import React, { useContext } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Image from "next/image";
import Reveal, { Fade } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import cx from "classnames";
import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { LogContext } from "context/log";
import { useIsMobile } from "@pepdirect/helpers/useIsMobile";
import { Button } from "@pepdirect/ui/button";
import { Heading } from "@pepdirect/ui/heading";
import { HeroCarousel } from "components/HeroCarousel";
import { getCloudflareLoader } from "@pepdirect/helpers/cloudflareImages";
import { META_TITLE, CANONICAL_URL_BASE } from "constants/meta";
import { endpoints } from "config";
import st from "./home.module.scss";
import { PAGE_TYPE_ENUM } from "@pepdirect/shared/types";
import { generatePageId } from "@pepdirect/helpers/analyticsLogger";

interface Card {
  img: string;
  title: string;
  text: string;
}

const benefits: Card[] = [
  {
    img: "/static-images/pepsico-shop-icons/b2b-images/benefits/ecomm-icon-2.png",
    title: "Save Time & Effort",
    text: "Keep your shelves stocked – in just a few clicks, your order will be on its way!",
  },
  {
    img: "/static-images/pepsico-shop-icons/b2b-images/benefits/ecomm-icon-1.png",
    title: "Insights & Tips",
    text: "Grow your business with monthly consumer insights and product recommendations tailored to your industry and location.",
  },
  {
    img: "/static-images/pepsico-shop-icons/b2b-images/benefits/ecomm-icon-3.png",
    title: "Excellent Customer Care",
    text: "Contact us and your inquiries will be dispatched to a dedicated customer service manager to help deliver you the best-in-class customer support.",
  },
];

const customerBenefits: Card[] = [
  {
    img: "/static-images/pepsico-shop-icons/b2b-images/tastes/beverages.png",
    title: "Variety and value",
    text: "Enjoy a large array of PepsiCo products available online and in one convenient location.",
  },
  {
    img: "/static-images/pepsico-shop-icons/b2b-images/tastes/flavors.png",
    title: "Flavors sure to please",
    text: "Play to your customers’ tastes with brands and flavors they love.",
  },
  {
    img: "/static-images/pepsico-shop-icons/b2b-images/tastes/sports-and-fitness.png",
    title: "Fuel for your busy customers",
    text: "Your customers are active and always on the go—and we’ve got a variety of performance drinks like Gatorade, Propel, and Muscle Milk to keep them at their best.",
  },
];

export default function Home(): JSX.Element {
  const { useLogPageView } = useContext(LogContext);

  useLogPageView({
    pageType: PAGE_TYPE_ENUM.home,
    pageCategory: undefined,
    pageTitle: META_TITLE,
    id: generatePageId("home"),
  });

  const { currentUserId } = useContext(UserContext);

  const isMobile = useIsMobile();
  const router = useRouter();
  const { open } = useContext(ModalContext);

  const goToSignIn = () => {
    if (isMobile) {
      router.push("/sign-in");
    } else {
      open("LOGIN_MODAL");
    }
  };

  return (
    <div>
      <Head>
        {/* TODO: og tags */}
        <title>{META_TITLE}</title>
        <link rel="canonical" href={CANONICAL_URL_BASE} />
      </Head>
      <section className={st.hero}>
        <div className={st.heroAction}>
          <Heading level="h1" size="xxl">
            Grow your business—we’ve got the products you want covered
          </Heading>
          <p>
            The most convenient way to order – purchase drinks online with
            convenient shipping directly to your front door
          </p>
          {currentUserId ? (
            <Button internalLink="/home">Shop now</Button>
          ) : (
            <Button onClick={goToSignIn}>Log in</Button>
          )}
        </div>
        <figure className={st.heroImageWrapper}>
          <Image
            className={st.heroImage}
            src="/static-images/pepsico-shop-icons/b2b-images/hero-illustration.png"
            width={500}
            height={500}
            loader={getCloudflareLoader(endpoints.cdn)}
            alt=""
            priority
          />
        </figure>
      </section>
      <HeroCarousel />
      <section className={st.trust}>
        <div className={st.wrapper}>
          <div className={st.details}>
            <Heading level="h2" size="xxl">
              Why businesses trust the PepsiCo&nbsp;Shop
            </Heading>
            <p className={st.subtitle}>
              See how PepsiCo Shop can add value to your business.
            </p>
          </div>
          <div className={st.benefitsWrapper}>
            {benefits.map(({ img, title, text }, index) => (
              <Reveal
                keyframes={fadeUpSmall}
                key={title}
                className={cx(st.benefit, ".animated-element")}
                delay={index * 250}
                fraction={isMobile ? 0 : 0.2}
                triggerOnce
              >
                <div>
                  <Image
                    src={img}
                    width={340}
                    height={340}
                    loader={getCloudflareLoader(endpoints.cdn)}
                    alt=""
                    priority
                  />
                  <h3>{title}</h3>
                  <p>{text}</p>
                </div>
              </Reveal>
            ))}
          </div>
        </div>
      </section>
      <section className={st.customerBenefits}>
        <div className={st.wrapper}>
          <div className={st.details}>
            <Heading level="h2" size="xxl">
              Put your customers&apos; taste first
            </Heading>
            <p className={st.subtitle}>
              PepsiCo Shop has all of the brands and flavors your customers
              love.
            </p>
          </div>
          <div className={st.customerBenefitsWrapper}>
            {customerBenefits.map(({ img, title, text }, index) => (
              <div className={st.customerBenefit} key={title}>
                <div>
                  <Fade
                    cascade
                    damping={0.4}
                    className=".animated-element"
                    triggerOnce
                  >
                    <div className={st.customerBenefitHeading}>
                      <Heading level="h3" size="xl">
                        {title}
                      </Heading>
                    </div>
                    <p>{text}</p>
                  </Fade>
                </div>
                <Reveal
                  className={cx(st.imageWrapper, ".animated-element")}
                  keyframes={index % 2 ? fadeLeftSmall : fadeRightSmall}
                  fraction={isMobile ? 0 : 0.3}
                  triggerOnce
                >
                  <Image
                    src={img}
                    width={400}
                    height={400}
                    loader={getCloudflareLoader(endpoints.cdn)}
                    alt=""
                    priority
                  />
                </Reveal>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={st.guarantee}>
        <Fade fraction={0.3} className=".animated-element" triggerOnce>
          <div className={st.text}>
            <Heading level="h2" size="xxl">
              We always put your business first, guaranteed.
            </Heading>
            <p className={st.subtitle}>
              At PepsiCo Shop, we value your business’s success. That’s why we
              guarantee the freshness of all of the products that we ship.
            </p>
          </div>
        </Fade>
        <div className={st.imageWrapper}>
          <Reveal
            fraction={0.1}
            className=".animated-element"
            triggerOnce
            delay={500}
            keyframes={fadeUpSmall}
          >
            <Image
              className={st.image}
              src="/static-images/pepsico-shop-icons/b2b-images/product-guarantee-section-image-v2.png"
              width={625}
              height={625}
              loader={getCloudflareLoader(endpoints.cdn)}
              alt=""
              priority
            />
          </Reveal>
        </div>
      </section>
      <section className={st.cta}>
        <Heading level="h2" size="xxl">
          Hungry for more?
          <br />
          Partner with PepsiCo&nbsp;Shop
        </Heading>
        <p className={st.subtitle}>
          Tap into the power of PepsiCo to grow your business.
        </p>
        {currentUserId ? (
          <Button internalLink="/home">Shop now</Button>
        ) : (
          <Button onClick={goToSignIn}>Log in</Button>
        )}
      </section>
    </div>
  );
}

const fadeUpSmall = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 5%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeLeftSmall = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeRightSmall = keyframes`
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
