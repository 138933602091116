const path = "/static-images/pepsico-shop-icons/b2b-images/brands";

// const ruffles = {
//   name: "Ruffles",
//   logo: `${path}/ruffles.png`,
//   category: "ruffles",
// };

const bubly = {
  name: "bubly",
  logo: `${path}/bubly.png`,
  category: "bubly",
};

// const cheetos = {
//   name: "Cheetos",
//   logo: `${path}/cheetos.png`,
//   category: "cheetos",
// };

// const doritos = {
//   name: "Doritos",
//   logo: `${path}/doritos.png`,
//   category: "doritos",
// };

// const fritos = {
//   name: "FRITOS",
//   logo: `${path}/fritos.png`,
//   category: "fritos",
// };

// const funyuns = {
//   name: "Funyuns",
//   logo: `${path}/funyuns.png`,
//   category: "funyuns",
// };

// const lays = {
//   name: "Lay's",
//   logo: `${path}/lays.png`,
//   category: "lays",
// };

const mountain = {
  name: "Mountain Dew",
  logo: `${path}/mtn-dew.png`,
  category: "mtn-dew",
};

const muscle = {
  name: "Muscle Milk",
  logo: `${path}/muscle-milk.png`,
  category: "muscle-milk",
};

const pepsi = {
  name: "Pepsi",
  logo: `${path}/pepsi.png`,
  category: "pepsi",
};

const propel = {
  name: "Propel",
  logo: `${path}/propel.png`,
  category: "propel",
};

const pure = {
  name: "Pure Leaf",
  logo: `${path}/pureleaf.png`,
  category: "pureleaf",
};

const rockstar = {
  name: "Rockstar",
  logo: `${path}/rockstar.png`,
  category: "rockstar",
};

const aqua = {
  name: "Aquafina",
  logo: `${path}/aquafina.png`,
  category: "aquafina",
};

// const starbucks = {
//   name: "Starbucks",
//   logo: `${path}/starbucks.png`,
//   category: "starbucks",
// };

const lifewtr = {
  name: "LIFEWTR",
  logo: `${path}/lifewtr.png`,
  category: "lifewtr",
};

const gatorade = {
  name: "Gatorade",
  logo: `${path}/gatorade.png`,
  category: "gatorade",
};

export const BRANDS = [
  bubly,
  mountain,
  muscle,
  pepsi,
  propel,
  pure,
  rockstar,
  aqua,
  lifewtr,
  gatorade,
];

// TODO: possibly get those from backend?
export const SELECTED_BRANDS = [
  pepsi,
  bubly,
  gatorade,
  lifewtr,
  mountain,
  rockstar,
];
